section {
  width: 100%;
  padding: 128px 0;

  @media (max-width: 1440px) {
    padding: 0;
  }
}

.content {
  width: 100%;
  box-sizing: border-box;
}

.inner {
  width: 1500px;
  margin: 0 auto;

  @media (max-width: 1500px) {
    width: 100%;
    padding: 80px 40px;
  }

  @media (max-width: 767px) {
    width: 100% !important;
    padding: 40px 16px;
    box-sizing: border-box;
  }
}

.title {
  text-align: center;

  h3 {
    font-size: 48px;
    font-weight: 700;
    letter-spacing: -1.28px;

    @media (max-width: 1199px) {
      font-size: 32px;
    }
    @media (max-width: 767px) {
      text-align: center;
      font-size: 22px;
      line-height: 32px;
      letter-spacing: -0.4px;
    }
  }
}

// headline1/b - 48px lh 56px ls-2%
// headline2/b - 36px lh 44px ls -2%
// headline3/b - 28px lh 36px ls -2%
// headline4/b
// body1/m/b - 18px lh 28px ls -1.5%
// body2/m/b - 16px lh 24px ls -1.5%
// body3/m

.pc {
  display: block;
}
.mo {
  display: none;
}

@media (max-width: 767px) {
  .pc {
    display: none;
  }
  .mo {
    display: block;
  }
}

/* ===============

Header

=============== */
header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  margin: 0 auto;
  z-index: 99999;

  .header-inner {
    padding: 15px 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo {
      width: 126px;
      height: 32px;
      background: url('../images/dn-logo.png') no-repeat;
      background-size: contain;
      transition: all 0.3s ease;

      a {
        display: block;
        width: 100%;
        height: 100%;
        text-indent: -9999px;
        overflow: hidden;
      }
    }

    ul {
      display: flex;
      gap: 5rem;
      font-weight: 600;
      font-size: 18px;

      li a {
        transition: color 0.3s ease;
        &:hover {
          color: #3287F0;
        }
      }
    }

    .contact-btn {
      border: 1px solid #fff;
      border-radius: 50px;
      padding: 12px 20px;
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.3s ease;

      &:hover {
        border: 1px solid #3287F0;
        transition: all 0.3s ease;
        color: #3287F0;
      }
    }
  }

  @media (max-width: 1440px) {
    .header-inner {
      padding: 15px 40px;

      .logo {
        width: 100px;
        height: 28px;
      }

      ul {
        font-size: 16px;
      }
    }
  }
}

.mobile-nav {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #000;
  
  .logo-container {
    overflow-y: hidden;
    padding: 14px 20px;

    .logo {
      width: 100px;
      height: 28px;
      background: url('../images/dn-logo.png') no-repeat;
      background-size: contain;

      a {
        display: block;
        width: 100%;
        height: 100%;
        text-indent: -9999px;
        overflow: hidden;
      }
    }
  }

  .menu-container {
    overflow-y: hidden;
    padding: 14px 20px;

    .hamburger-menu {
      width: 24px;
      height: 21px;
      position: relative;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .hamburger-menu span {
      display: block;
      width: 100%;
      height: 3px;
      background-color: white;
      border-radius: 3px;
    }

    div {
      text-transform: uppercase;
      font-size: 13px;
      color: #fff;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.mobile-menu {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: black;
  padding: 0;
  margin: 0;

  .close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
  }

  .close-icon {
    position: relative;
    width: 100%;
    height: 100%;
    display: block;
  }

  .close-icon::before,
  .close-icon::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30px;
    height: 3px;
    background-color: #fff;
    transition: transform 0.3s ease;
  }

  .close-icon::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  .close-icon::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  .inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  ul {
    list-style: none;

    li {
      margin: 40px 0;
      overflow-y: hidden;
      user-select: none;

      div {
        text-align: center;
        text-transform: capitalize;
        font-size: 34px;
      }

      &:hover {
        font-style: italic;
        cursor: pointer;
      }
    }
  }

  .contact {
    margin-top: 80px;
    display: flex;

    h5 {
      font-weight: normal;

      &:first-of-type {
        margin-right: 40px;
      }
    }
  }
}

/* ===============

Splash

=============== */
.splash-section {
  width: 100vw;
  height: 100vh;
  padding: 0;

  .bg {
    width: 100%;
    height: 100%;
    background-size: cover;
    position: relative;
    overflow: hidden;
    padding: 0;
    margin: 0;

    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: -1;
    }

    .text {
      h2 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 4rem;
        font-weight: 700;
        color: #fff;
        width: 100%;
        text-align: center;
        white-space: pre-line;

        @media (max-width: 1600px) {
          font-size: 2.4rem;
        }

        @media (max-width: 767px) {
          width: 80%;
          font-size: 2rem;
        }
      }
    }
  }
}

/* ===============

Footer

=============== */
footer {
  position: relative;
  width: 100%;
  text-align: left;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: -1;
    background-image: url('../images/bg-effects05.png');
    width: 800px;
    height: 800px;
    top: 0px;
    right: -400px;
  }

  .inner {
    width: 100%;
    max-width: 1500px;
    margin: 0 auto;
    box-sizing: border-box;

    .footer-top {
      display: flex;
      justify-content: space-between;
      padding: 24px 0;
      font-size: 18px;
      font-weight: 500;
      line-height: 32px;

      .info {
        display: flex;
        gap: 10px;
      }

      .icon-wrap {
        display: flex;
        gap: 48px;

        li {
          width: 48px;
          height: 48px;
          cursor: pointer;
          border-radius: 50%;
        }
      }
    }

    .footer-border {
      position: relative;
      width: 100%;
      height: 1px;

      &::before {
        position: absolute;
        left: 0;
        bottom: 0;
        content: '';
        width: 100%;
        border: 1px solid #fff;
      }
    }

    .footer-bottom {
      padding: 24px 0;
      font-size: 16px;

      ul {
        position: relative;
        display: flex;
        padding-bottom: 32px;
        gap: 10px;
        overflow: hidden;

        li {
          font-size: 16px;
        }
      }

      span {
        display: block;
        padding-top: 42px;
        font-size: 14px;
      }
    }
  }

  @media (max-width: 1600px) {
    &::after {
      width: 400px;
      height: 400px;
      top: 290px;
      right: -250px;
    }

    .inner {
      .footer-top {
        flex-direction: column-reverse;
        align-items: start;
        font-size: 14px;
        line-height: 24px;
        gap: 35px;

        .info {
          display: block;
          font-size: 14px;
          margin-top: 25px;
        }

        .icon-wrap {
          gap: 24px;

          li {
            width: 24px;
            height: 24px;

            svg {
              width: 24px;
              height: 24px;
            }
          }
        }
      }
      .footer-bottom {
        font-size: 14px;
        ul li {
          font-size: 14px;
        }

        span {
          padding-top: 30px;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .inner {
      padding: 40px 16px 0;
    }
  }
}

/* ===============

Scroll-Top

=============== */
.scroll-top-btn {
  position: fixed;
  right: 40px;
  bottom: 110px;
  border: 2px solid #fff;
  border-radius: 50%;
  width: 54px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000 !important;
  cursor: pointer;

  svg {
    width: 35px;
    height: 35px;
  }

  /* @media 기준이 아닌 모바일로 접속했을 때 */
  @media (max-width: 767px) {
    width: 42px;
    height: 42px;
    right: 15px;
    bottom: 75px;
    display: none;

    svg {
      width: 25px;
      height: 25px;
    }
  }
}
