$colors: (
  black: #000000,
  ash-black: #292929,
  white: #fafafa,
  sky: #00ccff,
  green: #22dddd,
  blue: #1300ff,
  dusk: #6600ff,
  purple: #9900ff,
  pink: #ff0066,
  red: #fe0222,
  orange: #fd7702,
  yellow: #ffbb00,
);

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@import url('https://cdn.jsdelivr.net/gh/sun-typeface/SUIT@2/fonts/static/woff2/SUIT.css');

body {
  --black: #000000;
  --ash-black: #292929;
  --white: #fafafa;
  --sky: #00ccff;
  --green: #22dddd;
  --blue: #1300ff;
  --dusk: #6600ff;
  --purple: #9900ff;
  --pink: #ff0066;
  --red: #fe0222;
  --orange: #fd7702;
  --yellow: #ffbb00;

  --background: var(--black);
  --accent: var(--white);

  margin: 0;
  padding: 0;
  background-color: var(--background);
  font-family: 'SUIT', sans-serif;
  color: var(--accent);
  font-size: 18px;

  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
}

html {
  scroll-behavior: smooth;
}

html,
body,
div,
span,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
dl,
dt,
dd,
ol,
ul,
li,
form,
label,
table,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
embed,
figure,
footer,
menu,
nav,
section,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
button {
  border: none;
  background: transparent;
}
button:focus,
button:active,
textarea:focus,
textarea:active,
input:focus,
input:active {
  box-shadow: none;
  outline: none;
}
ul,
ol,
li {
  list-style-type: none;
  padding: 0;
}
a {
  text-decoration: none;
  color: inherit;
}
* {
  font-family: 'SUIT';
  font-style: normal;
  -webkit-font-smoothing: antialiased;
}
button {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
