/* ===============

Main

=============== */
.main-section {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 1000px;
  overflow: hidden;
  transition: opacity 0.3s ease-out;
  will-change: opacity;

  .content {
    position: sticky;
    padding: 98px 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 650px;

    .title {
      position: sticky;
      width: 522px;

      h1 {
        font-weight: 600;
        text-align: left;
        font-size: 85px;
        line-height: 1;
        letter-spacing: -2%;

        &:nth-child(1) {
          margin-left: 75px;
        }

        &:nth-child(2) {
          text-align: right;
        }
      }
    }

    .text {
      display: flex;
      align-items: center;
      margin-top: 40px;

      p {
        color: #fff;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.32px;
        text-align: left;
      }
    }

    .icon {
      z-index: 100;
      > li {
        transition: all 1s ease;
        cursor: pointer;
      }

      .icon_chat {
        position: absolute;
        background: url('../images/Img_Chat.png') no-repeat;
        background-size: contain;
        top: -10px;
        left: 300px;
        width: 200px;
        height: 170px;

        @media (max-width: 1440px) {
          top: 10px;
          left: 160px;
          width: 90px;
          height: 75px;
        }
        @media (max-width: 991px) {
          top: 0px;
          left: 120px;
        }
        @media (max-width: 575px) {
          top: -50px;
          left: 40px;
          width: 58px;
          height: 45px;
        }
      }

      .icon_plus {
        position: absolute;
        background: url('../images/Img_Plus.png') no-repeat;
        background-size: contain;
        top: 88px;
        right: 300px;
        width: 130px;
        height: 153px;
        @media (max-width: 1440px) {
          top: 70px;
          right: 150px;
          width: 57px;
          height: 67px;
        }
        @media (max-width: 991px) {
          top: 30px;
          right: 120px;
        }
        @media (max-width: 575px) {
          top: -20px;
          right: 40px;
          width: 37px;
          height: 44px;
        }
      }

      .icon_lightning {
        position: absolute;
        background: url('../images/Img_Lightning.png') no-repeat;
        background-size: contain;
        left: 223px;
        bottom: 20px;
        width: 108px;
        height: 189px;

        @media (max-width: 1440px) {
          left: 150px;
          bottom: 10px;
          width: 51px;
          height: 89px;
        }
        @media (max-width: 991px) {
          left: 100px;
        }
        @media (max-width: 575px) {
          left: 35px;
          bottom: 100px;
          width: 33px;
          height: 57px;
        }
      }

      .icon_cursor {
        position: absolute;
        background: url('../images/icon_cursor.png') no-repeat;
        background-size: contain;
        right: 208px;
        bottom: 24px;
        width: 148px;
        height: 146px;

        @media (max-width: 1440px) {
          right: 120px;
          bottom: 60px;
          width: 67px;
          height: 66px;
        }
        @media (max-width: 991px) {
          right: 90px;
        }
        @media (max-width: 575px) {
          right: 60px;
          bottom: 145px;
          width: 41px;
          height: 40px;
        }
      }
    }
  }

  .bg {
    width: 100%;
    position: absolute;
    top: 680px;
    left: 0;
    height: 100%;

    > img {
      width: 100%;
    }
  }

  @media (max-width: 1440px) {
    padding: 100px 0 0;

    .content {
      padding: 64px 48px;
      height: 400px;

      .title {
        width: 280px;

        h1 {
          font-size: 46px;

          &:nth-child(1) {
            margin-left: 40px;
          }
        }
      }
    }
    .bg {
      top: 530px;
    }
  }

  @media (max-width: 767px) {
    .content {
      padding: 0;
      height: 300px;
      margin-top: 50px;

      .title {
        width: 240px;
        h1 {
          font-size: 40px;
          line-height: 48px;
          &:nth-child(1) {
            margin-left: 32px;
          }
        }
      }

      .text p {
        text-align: center;
        font-size: 14px;
        line-height: 1.25rem;
      }
    }
    .bg {
      top: 450px;
    }
  }
}

.hidden {
  opacity: 0;
  visibility: hidden;
  transition:
    opacity 0.3s,
    visibility 0.3s;
}

/* ===============
  
  About
  
  =============== */
.about-section {
  position: relative;
  margin-top: 1100px;
  background: #000;
  z-index: 500;
  padding-top: 0;

  .content {
    .title {
      width: 100%;
      margin-bottom: 60px;
      text-align: left;
    }

    .text {
      width: 100%;
      font-size: 1rem;
      line-height: 1.6rem;
      font-weight: 400;
    }

    .about {
      display: flex;
      justify-content: end;
      align-items: end;
      gap: 145px;
      height: 400px;
      display: flex;
      flex-direction: wrap-reverse;

      .step-box {
        position: relative;
        width: auto;

        h4 {
          font-size: 56px;
          text-align: center;
          letter-spacing: -0.8px;
          padding-bottom: 12px;
        }

        .text-box {
          position: absolute;
          left: -320px;
          top: 80px;
          display: block;
          width: auto;
          font-size: 14px;

          .bold {
            font-weight: 700;
            margin-bottom: 10px;
            font-size: 18px;
          }

          &::before {
            content: '';
            border: 1px solid #fff;
            width: 120px;
            display: block;
            position: absolute;
            right: -150px;
            top: 59px;
          }

          &::after {
            content: '';
            width: 8px;
            height: 8px;
            background: #fff;
            border-radius: 50%;
            position: absolute;
            right: -150px;
            top: 56px;
          }
        }

        &:nth-child(1) .text-box {
          margin-left: 35px;
        }

        &:nth-child(2) .text-box {
          margin-left: 10px;
        }
      }

      .box {
        margin: 0 auto;
        width: 150px;
        height: 600px;
        border-radius: 5px;
        background: linear-gradient(
          0deg,
          rgba(51, 150, 255, 0) 0%,
          #3396ff 66.46%
        );
      }
    }
  }

  @media (max-width: 1440px) {
    margin-top: 1000px;
    text-align: center;
    padding-bottom: 100px;

    .content {
      .title {
        text-align: center;
      }
      .text {
        font-size: 1rem;
        line-height: 1.6rem;
      }

      .about {
        width: 600px;
        height: 340px;
        margin: 40px auto;
        justify-content: space-between;
        gap: 0;

        .step-box {
          h4 {
            font-size: 26px;
          }

          .text-box {
            position: absolute;
            left: -70%;
            top: 100%;
            width: 240px;
            font-size: 14px;
            margin-top: 20px;

            .bold {
              font-weight: 800;
              margin-bottom: 10px;
              font-size: 18px;
            }

            &::before,
            &::after {
              display: none;
            }
          }

          &:nth-child(1) .text-box {
            margin-left: 0;
          }

          &:nth-child(2) .text-box {
            margin-left: 0;
          }
        }

        .box {
          width: 100px;
        }
      }

      .mobile-text-item {
        padding: 30px 0;

        h4 {
          font-size: 18px;
          margin-bottom: 15px;
        }
        p {
          font-size: 14px;
        }
      }
    }
  }

  @media (max-width: 1199px) {
    margin-top: 900px;
    .content {
      .about {
        height: 350px;
      }
    }
  }

  @media (max-width: 767px) {
    position: relative;
    overflow: hidden;
    margin-top: 700px;
    padding-bottom: 40px;

    &::before,
    &::after,
    .bg-effect01,
    .bg-effect02 {
      content: '';
      position: absolute;
      background-repeat: no-repeat;
      background-size: contain;
      z-index: -1;
    }

    &::before {
      background-image: url('../images/bg-effects06.png');
      width: 320px;
      height: 280px;
      top: 0px;
      right: -200px;
      animation: float2 6s ease-in-out infinite;
    }

    &::after {
      background-image: url('../images/bg-effects07.png');
      width: 350px;
      height: 350px;
      top: 250px;
      left: -240px;
      animation: float2 6s ease-in-out infinite;
    }

    .bg-effect01 {
      background-image: url('../images/bg-effects08.png');
      width: 320px;
      height: 320px;
      bottom: 240px;
      right: -180px;
      transform: translateX(-50%);
      animation: float2 6s ease-in-out infinite;
    }

    .bg-effect02 {
      background-image: url('../images/bg-effects09.png');
      width: 300px;
      height: 300px;
      bottom: 0px;
      left: -150px;
      animation: float2 6s ease-in-out infinite;
    }

    .content {
      .title {
        margin-bottom: 40px;
      }
      .text {
        max-width: 80%;
        margin: 0 auto;
        font-size: 0.9rem;
        line-height: 1.5rem;
      }

      .about {
        width: 300px;

        .step-box {
          h4 {
            font-size: 20px;
          }
          .text-box {
            display: none;
          }
        }

        .box {
          width: 65px;
        }
      }
    }
  }

  @media (max-width: 430px) {
    .content {
      .title {
        margin-bottom: 40px;
      }
      .text {
        max-width: 100%;
      }
    }
  }
}

/* ===============
  
  Service
  
  =============== */
.service-section {
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 130px;
    align-self: stretch;

    .title {
      text-align: center;
    }

    .service-wrap {
      position: relative;
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;

      .service-item {
        display: flex;
        overflow: hidden;
      }
    }

    .logo {
      font-weight: 600;
      font-size: 32px;
      opacity: 0.5;
    }

    .motion-wrap {
      position: relative;
      width: 100%;
      overflow: hidden;
      padding: 20px 0;

      .motion {
        display: flex;
        width: 100%;
        position: relative;

        .motion-item {
          display: flex;
          white-space: nowrap;
          will-change: transform;
          gap: 100px;

          .item {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            min-width: 150px;
            flex-shrink: 0;
            font-size: 40px;
            opacity: 0.5;
            font-weight: 700;
          }
        }
      }
    }
  }

  @media (max-width: 1199px) {
    .content {
      padding: 80px 0;
      gap: 90px;
    }
  }

  @media (max-width: 767px) {
    .content {
      padding: 40px 0;
      gap: 60px;
    }
  }
}

.marquee-advanced {
  width: 100%;
  overflow: hidden;
  position: relative;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    width: 50px;
    height: 100%;
    z-index: 2;
  }

  &::before {
    left: 0;
    /*  background: linear-gradient(to left, rgb(29, 28, 28), transparent);*/
  }

  &::after {
    right: 0;
    /*  background: linear-gradient(to left, rgb(29, 28, 28), transparent);*/
  }

  .marquee-track-advanced {
    display: flex;
    width: fit-content;
    animation: marquee-advanced 40s linear infinite;

    .marquee-content-advanced {
      display: flex;
      gap: 12rem;

      &:last-child {
        margin: 0 12rem;
      }

      .marquee-image {
        display: flex;
        align-items: center;
        justify-content: center;
        object-fit: cover;
        border-radius: 8px;
        transition: transform 0.3s ease;
        opacity: 0.6;
        text-align: center;

        > img {
          height: 48px;
        }

        :hover {
          transform: scale(1.05);
        }
      }

      @media screen and (max-width: 1199px) {
        gap: 8rem;

        &:last-child {
          margin: 0 8rem;
        }

        .marquee-image {
          > img {
            height: 28px;
          }
        }
      }

      @media screen and (max-width: 767px) {
        gap: 4rem;

        &:last-child {
          margin: 0 4rem;
        }
      }
    }
  }
}

@keyframes marquee-advanced {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.hover-card-section {
  width: 1600px;
  gap: 20px;
  display: flex;
  justify-content: space-between;

  .hover-card {
    position: relative;
    flex: 1;
    height: 740px;
    overflow: hidden;
    border-radius: 14px;
    transition: all 1s cubic-bezier(0.4, 0, 0.2, 1);
    text-align: left;
    background: #3f3f3f;
    color: #fff;
    cursor: pointer;

    .thumbnail {
      width: 100%;
      height: 100%;
      transition: all 1s cubic-bezier(0.4, 0, 0.2, 1);
      display: block;

      .thumb-bg {
        width: 100%;
        height: 100%;
        position: absolute;
        mix-blend-mode: soft-light;

        &.thumb1 {
          background: url('../images/img_ai.png') no-repeat;
          background-position: 280px 280px;
        }
        &.thumb2 {
          background: url('../images/img_mr.png') no-repeat;
          background-position: 180px 460px;
        }
        &.thumb3 {
          background: url('../images/img_vr.png') no-repeat;
          background-position: 228px 260px;
        }
      }

      span {
        position: absolute;
        top: 60px;
        right: -15px;
        font-size: 200px;
        font-weight: 800;
        line-height: 1;
        letter-spacing: -10px;
        opacity: 0.4;
      }

      .text {
        position: absolute;
        bottom: 40px;
        left: 40px;
        font-size: 30px;
        font-weight: 500;
        line-height: 40px;
        padding: 0;
      }
    }

    &:nth-child(1) span {
      right: 10px;
    }

    .hover-item {
      position: relative;
      width: 100%;
      height: 100%;
      display: none;
      transition: all 1s cubic-bezier(0.4, 0, 0.2, 1);

      .thumb-bg {
        position: absolute;
        width: 100%;
        height: 100%;
        mix-blend-mode: soft-light;

        &.thumb1 {
          background: url('../images/img_ai.png') no-repeat;
          background-position: right -60px bottom 60px;
        }
        &.thumb2 {
          background: url('../images/img_mr.png') no-repeat;
          background-position: right -25px bottom 120px;
        }
        &.thumb3 {
          background: url('../images/img_vr.png') no-repeat;
          background-position: right -50px bottom -50px;
        }
      }

      .item {
        height: 100%;
        padding: 90px 60px;

        .flex {
          display: flex;
          justify-content: space-between;

          .close-btn {
            display: none;
          }

          .left {
            display: flex;
            flex-direction: column;
            gap: 60px;

            p {
              width: 420px;
              font-size: 18px;
              font-weight: 500;
              line-height: 28px;
              margin-top: 30px;
            }
          }

          .right {
            width: 100%;
            text-align: right;
          }

          h4 {
            font-size: 28px;
            line-height: 42px;
            margin: 0;
          }
        }

        .client {
          margin-top: 60px;
          font-weight: 700;
          font-size: 18px;
        }
      }
    }

    &:hover {
      flex: 3;
      transition: all 1s cubic-bezier(0.4, 0, 0.2, 1);
      background: #3075dc;

      .thumbnail {
        display: none;
      }

      .hover-item {
        display: block;
      }
    }
  }

  &:hover {
    .thumbnail .text {
      opacity: 0;
      transition: opacity 0.3s ease;
    }

    .thumbnail .thumb-bg {
      &.thumb1 {
        background-position: 80px 280px;
        transition: background-position 1s cubic-bezier(0.4, 0, 0.2, 1);
      }

      &.thumb2 {
        background-position: 80px 460px;
        transition: background-position 1s cubic-bezier(0.4, 0, 0.2, 1);
      }

      &.thumb3 {
        background-position: 80px 260px;
        transition: background-position 1s cubic-bezier(0.4, 0, 0.2, 1);
      }
    }
  }

  @media (max-width: 1600px) {
    width: 100%;
    box-sizing: border-box;
    padding: 0 30px;
  }

  @media (max-width: 1199px) {
    width: 94%;
    gap: 16px;
    padding: 0;
    transition: gap 0.5s cubic-bezier(0.4, 0, 0.2, 1);

    &:has(.hover-card:hover) {
      gap: 0;
    }

    .hover-card {
      width: 100%;
      height: 400px;
      border-radius: 8px;
      position: relative;

      .thumbnail {
        .thumb-bg {
          &.thumb1 {
            background-size: contain;
            background-position: 110px 90px;
          }
          &.thumb2 {
            background-size: contain;
            background-position: 90px 220px;
          }
          &.thumb3 {
            background-size: contain;
            background-position: 70px 105px;
          }
        }

        span {
          top: 20px;
          right: -2px;
          font-size: 85px;
          letter-spacing: -1px;
        }

        .text {
          position: absolute;
          bottom: 16px;
          left: 16px;
          font-size: 18px;
          line-height: 26px;
        }
      }

      &:hover,
      &.hover {
        flex: none;
        ~ .hover-card {
          flex: 0;
        }
      }

      .hover-item {
        width: 100%;
        padding: 30px;
        box-sizing: border-box;

        .item {
          padding: 0;

          .flex {
            padding: 0;

            h4 {
              font-size: 20px;
              line-height: 1.5;
            }

            .left {
              gap: 20px;

              p {
                font-size: 16px;
                line-height: 1.5;
              }
            }

            .right {
              display: none;
            }
          }

          .client {
            margin-top: 40px;
            font-size: 16px;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    gap: 10px;

    .hover-card {
      height: 300px;

      .thumbnail {
        .thumb-bg {
          &.thumb1 {
            background-position: 50px 120px;
          }
          &.thumb2 {
            background-position: 30px 190px;
          }
          &.thumb3 {
            background-position: 40px 105px;
          }
        }
        span {
          font-size: 48px;
        }
        .text {
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
        }
      }

      .hover-item {
        padding: 20px;

        .thumb-bg {
          &.thumb1 {
            background-size: 180px;
            background-position: right -40px bottom 30px;
          }
          &.thumb2 {
            background-size: 180px;
            background-position: right -5px bottom 40px;
          }
          &.thumb3 {
            background-size: 180px;
            background-position: right -30px bottom 5px;
          }
        }

        .item {
          .flex {
            h4 {
              font-size: 18px;
              line-height: 1.5;
            }

            .left {
              width: 100%;
              gap: 0;

              p {
                width: 100%;
                font-size: 13px;
                line-height: 1.5;
              }
            }
          }

          .client {
            margin-top: 30px;
            font-size: 13px;
          }
        }
      }
    }
  }
}

/* ===============
  
  Project
  
  =============== */
.project-section {
  position: relative;
  overflow: hidden;

  &::before,
  &::after {
    content: '';
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: -1;
  }

  &::before {
    background-image: url('../images/bg-effects01.png');
    width: 1100px;
    height: 1100px;
    top: -100px;
    left: -500px;
    animation: float 6s ease-in-out infinite;
  }

  &::after {
    background-image: url('../images/bg-effects02.png');
    width: 1000px;
    height: 1000px;
    top: 40%;
    right: -500px;
    animation: float 6s ease-in-out infinite;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 130px;
    align-self: stretch;

    .project-wrap {
      display: flex;
      flex-direction: column;
      gap: 250px;

      .project {
        width: 1500px;
        display: flex;
        justify-content: space-between;

        &:nth-child(even) {
          flex-direction: row-reverse;
        }

        .project-text {
          width: 600px;
          text-align: left;
          padding: 25px 0;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          h6 {
            font-size: 42px;
            font-weight: 700;
            line-height: 58px;
            margin-bottom: 30px;
          }

          p {
            font-size: 18px;
            line-height: 28px;
            font-weight: 500;
            max-width: 450px;
          }

          .link {
            width: 150px;
            height: 60px;
            padding: 0 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50px;
            cursor: pointer;
            background: #fff;

            a {
              font-size: 20px;
              font-weight: 700;
              color: #000000;
              white-space: nowrap;
            }

            &:hover {
              background: #2e63ca;
              transition: background 0.6s ease;
              a {
                color: #fff;
                transition: color 0.6s ease;
              }
            }
          }
        }

        .project-image {
          width: 600px;
          height: 400px;
          border-radius: 12px;

          &.project1 {
            background: url('../images/project_01.jpg') no-repeat;
            background-size: cover;
            background-position: center;
          }

          &.project2 {
            background: url('../images/project_02.jpg') no-repeat;
            background-size: cover;
            background-position: bottom;
          }

          &.project3 {
            background: url('../images/project_03.jpg') no-repeat;
            background-size: cover;
            background-position: center;
          }
        }
      }
    }
  }

  @media (max-width: 1600px) {
    .content {
      .project-wrap {
        width: 100%;

        .project {
          width: 100%;
        }
      }
    }
  }

  @media (max-width: 1199px) {
    .content {
      .project-wrap {
        gap: 120px;

        .project {
          .project-text {
            width: 45%;
            padding: 0;

            h6 {
              font-size: 20px;
              line-height: 28px;
              margin: 0 0 20px;
            }

            p {
              font-size: 16px;
              line-height: 24px;
              width: 100%;
            }

            .link {
              margin-top: 20px;
              width: 110px;
              height: 40px;

              a {
                font-size: 14px;
              }
            }
          }

          .project-image {
            width: 45%;
            height: 320px;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    &::before {
      background-image: url('../images/bg-effects01.png');
      left: 0;
      width: 340px;
      height: 300px;
      top: 45%;
      left: -220px;
      animation: float2 6s ease-in-out infinite;
    }

    &::after {
      background-image: url('../images/bg-effects02.png');
      width: 300px;
      height: 300px;
      top: 300px;
      right: -140px;
      animation: float2 6s ease-in-out infinite;
    }

    .content {
      gap: 40px;

      .project-wrap {
        gap: 64px;
        width: 100%;

        .project {
          width: 100%;
          flex-direction: column-reverse;

          &:nth-child(even) {
            flex-direction: column-reverse;
            align-items: end;

            .project-text {
              text-align: right;
              display: flex;
              align-items: end;
              p {
                float: right;
              }
            }
          }

          .project-text {
            width: 100%;
            display: block;
            padding: 0;

            h6 {
              font-size: 20px;
              line-height: 28px;
              margin: 30px 0 20px;
            }

            p {
              font-size: 14px;
              line-height: 20px;
              width: 80%;
            }

            .link {
              margin-top: 20px;
              width: 110px;
              height: 40px;

              a {
                font-size: 14px;
              }
            }
          }

          .project-image {
            width: 60%;
            height: 280px;
          }
        }
      }
    }
  }

  @media (max-width: 510px) {
    .content {
      .project-wrap {
        .project {
          .project-image {
            width: 80%;
            height: 200px;
          }
        }
      }
    }
  }
}

@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-60px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes float2 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-30px);
  }
  100% {
    transform: translateY(0);
  }
}
/* ===============
  
  slider
  
  =============== */
.slider-section {
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 100px;

    .swiper-wrap {
      width: 100%;
      height: 700px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      padding: 0;
      box-sizing: border-box;

      .swiper-wrapper {
        width: 100%;
        height: 600px;
        display: flex;
        align-items: center;

        .swiper-slide {
          width: 467px;
          height: 550px;
          transition: all 0.3s ease;
          display: flex;
          align-items: center;
          justify-content: center;

          .portfolio-card {
            width: 100%;
            height: 100%;
            border-radius: 20px;
            display: flex;
            flex-direction: column;
            cursor: pointer;
            transition: all 0.3s ease;
            transform-origin: center;
            overflow: hidden;

            .image {
              width: 100%;
              height: 100%;
              border-radius: 20px 20px 0 0;
              overflow: hidden;
              position: relative;

              img {
                width: 100%;
                height: 100%;
                border-radius: 20px 20px 0 0;
                object-fit: cover;
                object-position: center;
                transform: scale(1.6);
                overflow: hidden;

                &.thumbnail {
                  transform: scale(1);
                }
                &.yplanet {
                  transform: scale(1);
                  object-position: top;
                }
                &.universal {
                  object-position: left;
                  transform: scale(1.6);
                  position: absolute;
                  left: 0%;
                  top: 0;

                  @media (max-width: 1200px) {
                    transform: scale(1.8);
                    left: 18%;
                  }

                  @media (max-width: 575px) {
                    transform: scale(2);
                    left: 30%;
                  }
                }
                &.youngshine,
                &.ku {
                  transform: scale(1.8);
                }
                &.shinbi {
                  object-position: left;
                  transform: scale(1.4);
                  position: absolute;
                  left: 3%;
                  top: -15%;

                  @media (max-width: 1200px) {
                    transform: scale(1.8);
                    left: 40%;
                  }
                  @media (max-width: 575px) {
                    transform: scale(1.8);
                    left: 30%;
                  }
                }
                &.kt {
                  transform: scale(1.2);
                }
                &.skt {
                  transform: scale(1.1);
                }
              }
            }

            .text {
              position: relative;
              height: 221px;
              background: #fff;
              border-radius: 0 0 20px 20px;
              text-align: left;

              h4 {
                font-size: 28px;
                line-height: 1.25;
                padding: 24px;
                font-weight: 700;
                color: #333;
              }

              span {
                position: absolute;
                bottom: 22px;
                left: 24px;
                font-size: 18px;
                color: rgba(0, 0, 0, 0.74);
                font-weight: 700;
              }
            }

            .more-btn {
              color: #666;
              font-size: 16px;
            }
          }
        }
      }

      @media (max-width: 1199px) {
        height: 400px;

        .swiper-wrapper .swiper-slide {
          height: 293px;

          .portfolio-card {
            .image {
              height: 234px;
            }

            .text {
              height: 116px;

              h4 {
                font-size: 17px;
                letter-spacing: -.4px;
                padding: 15px;
              }

              span {
                font-size: 14px;
                bottom: 15px;
                left: 15px;
              }
            }
          }
        }
      }

      @media (max-width: 767px) {
        height: 320px;

        .swiper-wrapper .swiper-slide {
          height: 300px;

          .portfolio-card {
            .image {
              height: 185px;
            }

            .text {
              height: 120px;
            }
          }
        }
      }

      @media (max-width: 430px) {
        height: 270px;

        .swiper-wrapper .swiper-slide {
          height: 200px;

          .portfolio-card {
            .image {
              height: 120px;
            }

            .text {
              height: 80px;

              h4 {
                font-size: 12px;
                padding: 10px;
              }

              span {
                font-size: 8px;
                bottom: 10px;
                left: 10px;
              }
            }
          }
        }
      }
    }

    .swiper-slide-container {
      height: 100%;
      margin: auto;
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
    }

    .swiper-pagination {
      display: none;
    }

    .swiper-wrap .swiper-slide.swiper-slide-active {
      width: 510px !important;
      height: 600px !important;

      .portfolio-card {
        width: 100%;
        height: 100%;
        
        .image {
          img {
            &.universal {
              object-position: left;
              transform: scale(1.6);
              position: absolute;
              left: 11%;
              top: 0;

              @media (max-width: 1200px) {
                transform: scale(1.8);
                left: 20%;
              }
            }
            &.shinbi {
              object-position: left;
              transform: scale(1.5);
              position: absolute;
              left: 15%;
              top: -22%;

              @media (max-width: 1200px) {
                transform: scale(1.6);
                left: 30%;
              }
              @media (max-width: 575px) {
                transform: scale(1.6);
                left: 25%;
              }
            }
          }
        }
        .text {
          height: 280px;

          h4 {
            font-size: 34px;
          }
          span {
            font-size: 20px;
          }
        }
      }

      @media (max-width: 1199px) {
        width: 300px !important;
        height: 340px !important;

        .portfolio-card .text {
          height: 130px;

          h4 {
            font-size: 18px;
          white-space: nowrap;
          }
          span {
            font-size: 16px;
          }
        }
      }

      @media (max-width: 767px) {
        width: 310px !important;
        height: 320px !important;

        .portfolio-card .image {
          height: 225px;
        }
        .portfolio-card .text {
          height: 145px;
        }
      }
      @media (max-width: 430px) {
        width: 180px !important;
        height: 215px !important;

        .portfolio-card .image {
          height: 130px;
        }
        .portfolio-card .text {
          height: 86px;
        }
        .portfolio-card .text h4 {
          font-size: 13px;
        }
        .portfolio-card .text span {
          font-size: 10px;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .content {
      padding: 40px 0;
      gap: 40px;
    }
  }
}

/* ===============
  
  Contact
  
  =============== */
.contact-section {
  position: relative;
  overflow: hidden;
  box-sizing: border-box;

  &::before,
  &::after {
    content: '';
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: -1;
  }

  &::before {
    background-image: url('../images/bg-effects04.png');
    width: 800px;
    height: 800px;
    top: 0px;
    left: -350px;
    animation: float 6s ease-in-out infinite;
  }

  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 130px;
    align-self: stretch;

    .contact {
      width: 1200px;
      padding: 64px 128px;
      background: #2e63ca;
      border-radius: 25px;

      .form-header {
        display: flex;
        justify-content: space-between;
        padding: 50px 0;
        text-align: left;

        h4 {
          font-size: 44px;
          font-weight: 700;
          line-height: 54px;
        }

        p {
          font-size: 18px;
          font-weight: 500;
          line-height: 26px;
        }
      }

      .form-wrap {
        form {
          .flex {
            width: 100%;
            display: flex;
            justify-content: space-between;
            gap: 120px;
            font-size: 18px;
            padding: 50px 0;

            .item {
              width: 50%;
              display: flex;
              flex-direction: column;
              gap: 50px;

              > div {
                position: relative;
                display: flex;
                flex-direction: column;
                gap: 10px;
                text-align: left;
                width: 100%;

                .error-message {
                  position: absolute;
                  top: 80px;
                  color: var(--Red-400, #eb5b5b);
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 20px;
                  letter-spacing: -0.21px;
                  transition:
                    opacity 0.3s ease,
                    transform 0.3s ease;

                  @media (max-width: 1440px) {
                    top: 56px;
                    font-size: 13px;
                  }
                  @media (max-width: 767px) {
                    width: 100%;
                    display: flex;
                    justify-content: end;
                  }
                }
              }
            }
          }

          input,
          textarea {
            background: transparent;
            box-shadow: none;
            border: none;
            border-bottom: 1px solid #fff;
            padding: 10px 0;
            color: #fff;
            font-size: 1rem;
            /* error message 나올 때 border color : #eb5b5b로 변경 */
            &.error {
              border-bottom-color: #eb5b5b;
            }
          }

          textarea {
            height: 144px;
            resize: none;
          }

          input::placeholder,
          textarea::placeholder {
            color: #a3b5d0;
            font-size: 18px;
          }

          .btn-wrap {
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            input {
              display: flex;
              width: 150px;
              height: 60px;
              padding: 0px 25px;
              justify-content: center;
              align-items: center;
              font-size: 22px;
              background: #fff;
              color: #2e63ca;
              border-radius: 50px;
              border: none;
              font-weight: 700;
              cursor: pointer;
            }
          }
        }
      }
    }

    .contact-modal {
      position: fixed;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.74);
      z-index: 99999;
      display: none;

      &.open {
        display: block;
      }

      .modal-content {
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #fff;
        width: 400px;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
        color: #000;
        text-align: center;
        padding: 40px 0;

        h6 {
          font-size: 20px;
        }
        p {
          font-size: 14px;
          margin-top: 10px;
        }
        .close-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          width: 100px;
          height: 48px;
          padding: 0 20px;
          border: 1px solid #292929;
          border-radius: 50px;
          font-size: 18px;
          font-weight: 700;

          &:hover {
            background: #2e63ca;
            border: #2e63ca;
            color: #fff;
            transition: background 1.6s ease;
          }
        }
      }
    }
  }

  @media (max-width: 1440px) {
    .content {
      margin: 80px 0 0;
      padding: 0 40px;

      .contact {
        width: 100%;
        box-sizing: border-box;
        padding: 40px;
        border-radius: 18px;

        .form-header {
          padding: 0;
          margin-bottom: 40px;
          h4 {
            font-size: 24px;
            line-height: 1.5;
          }
          p {
            width: 340px;
            font-size: 14px;
            line-height: 1.5;
          }
        }
        .form-wrap {
          form {
            .flex {
              flex-direction: column;
              font-size: 14px;
              gap: 48px;

              .item {
                width: 100%;
                > div {
                  gap: 0;
                }
              }
              input,
              textarea {
                padding: 6px 0;
              }
              input::placeholder,
              textarea::placeholder {
                font-size: 14px;
              }
            }
            .btn-wrap {
              input {
                width: 102px;
                height: 48px;
                padding: 0px;
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .content {
      margin: 60px 0 20px;
      padding: 0 16px;

      .contact {
        padding: 40px 20px;

        .form-header {
          padding: 0;
          margin-bottom: 0;
          flex-direction: column;
          gap: 20px;

          p {
            width: 280px;
          }
        }
        .form-wrap {
          form {
            .flex {
              gap: 30px;
              padding: 60px 0 30px;
              .item {
                gap: 30px;
              }
            }
          }
        }
      }

      .contact-modal {
        width: 100%;

        .modal-content {
          width: 92%;
          border-radius: 6px;
          gap: 15px;
          color: #000;
          text-align: center;

          svg {
            width: 40px;
            height: 40px;
          }
          h6 {
            font-size: 18px;
          }
          p {
            margin-top: 6px;
          }
          .close-btn {
            width: 90px;
            height: 36px;
            padding: 0 20px;
            font-size: 16px;
          }
        }
      }
    }
  }
}
