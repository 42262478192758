.portfolio-section {
  width: 100%;
  height: 100%;
  background: #fff;
  padding: 0;

  .header {
    position: relative;
    height: 400px;
    overflow: hidden;

    img.header-bg {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    .close-btn {
      position: absolute;
      right: 60px;
      top: 60px;
      border: 2px solid #fff;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    .prev-btn {
      position: absolute;
      left: 60px;
      bottom: 60px;
      cursor: pointer;
    }
    .next-btn {
      position: absolute;
      right: 60px;
      bottom: 60px;
      cursor: pointer;
    }

    @media (max-width: 1440px) {
      height: 320px;

      .close-btn {
        right: 30px;
        top: 30px;
      }

      .prev-btn {
        left: 30px;
        bottom: 30px;
      }

      .next-btn {
        right: 30px;
        bottom: 30px;
      }
    }

    @media (max-width: 767px) {
      height: 240px;
      background: #000;

      .close-btn {
        right: 15px;
        top: 15px;

        > svg {
          width: 28px;
          height: 28px;
        }
      }
      .prev-btn {
        left: 15px;
        bottom: 15px;

        > svg {
          width: 28px;
          height: 25px;
        }
      }

      .next-btn {
        right: 15px;
        bottom: 15px;

        > svg {
          width: 28px;
          height: 25px;
        }
      }
    }
  }

  .inner {
    width: 1400px;
    margin: 0 auto;
    color: #292929;
    padding: 120px 0;
    line-height: 1.5;
    display: flex;
    flex-direction: column;
    gap: 120px;
    box-sizing: border-box;

    .text-wrap {
      .title-wrap {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 64px;

        h2 {
          font-size: 60px;
          line-height: 0;
        }
        .year {
          margin-top: 40px;
          font-size: 28px;
          font-weight: 500;
          line-height: 36px;
          letter-spacing: -0.56px;
        }
      }
      h4 {
        font-size: 36px;
        margin-top: 35px;
        font-weight: 500;
      }
      .text {
        width: 53%;
        font-size: 18px;
        margin-top: 80px;
      }
      .icon-box {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-top: 60px;
      }
    }

    .flex-wrap {
      display: flex;
      flex-direction: row;

      h5 {
        width: 200px;
        font-weight: 700;
        font-size: 20px;
      }
      .grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 100px;
        > div {
          width: 200px;
        }
        h6 {
          font-weight: 600;
          margin-bottom: 10px;
          font-size: 18px;
        }
      }
    }

    .img-wrap {
      width: 100%;
      display: flex;
      flex: 1 0 0;
      align-items: flex-start;
      gap: 60px;

      .img {
        width: 546px;
        height: 300px;
        border-radius: 10px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: left;
          image-rendering: -webkit-optimize-contrast; 
          transform: translateZ(0);
          backface-visibility: hidden; 
        }
      }
    }

    &.inner-mo {
      padding-top: 0;
    }
    @media (max-width: 1440px) {
      width: 100%;
      padding: 40px;
      gap: 80px;

      .text-wrap {
        .title-wrap {
          h2 {
            font-size: 36px;
            line-height: 1;
          }
        }
        h4 {
          font-size: 20px;
        }
        .text {
          width: 580px;
          font-size: 16px;
          margin-top: 40px;
        }
      }

      .flex-wrap {
        width: 100%;

        .grid {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
        }
      }

      .icon-box {
        svg {
          width: 40px;
          height: 40px;
        }
      }

      .img-wrap {
        width: 100%;
        overflow: hidden;
        justify-content: space-between;
        gap: 0;
      }

      &.inner-mo {
        padding: 0;
      }
    }

    @media (max-width: 1090px) {
      .img-wrap {
        .img {
          border-radius: 0;
        }
      }
    }
    @media (max-width: 767px) {
      padding: 30px 16px 100px;

      .text-wrap {
        .title-wrap {
          display: block;
          h2 {
            font-size: 28px;
          }
          .year {
            margin-top: 16px;
            font-size: 14px;
          }
        }
        h4 {
          font-size: 16px;
          margin-top: 30px;
        }
        .text {
          width: 100%;
          font-size: 16px;
          margin-top: 60px;
        }
      }

      .flex-wrap {
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 40px;

        h5 {
          font-size: 18px;
          font-weight: 800;
        }

        .grid {
          display: grid;
          grid-template-columns: 2fr;
          gap: 40px;
          > div {
            width: 100%;
          }
          h6 {
            font-size: 16px;
            font-weight: 700;
          }
          p {
            width: 60%;
            margin: 0 auto;
          }
        }
      }

      .img-wrap {
        width: 100%;
        height: auto;
        flex-direction: column;

        .img {
          width: 100%;
          height: 230px;

          img {
            width: 100%;
          }
        }
      }
    }
  }
}

.privacy-container {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background: #fff;
  color: #222;
  margin: 0 auto;
  box-sizing: border-box;

  .prev-btn {
    position: absolute;
    top: 40px;
    left: 40px;
    width: 50px;
    height: 50px;
    background: url('../images/Previous_Black.png') no-repeat;
    background-size: contain;
    cursor: pointer;
  }
  .close-btn {
    position: absolute;
    top: 40px;
    right: 40px;
    width: 50px;
    height: 50px;
    background: url('../images/Close_Black.png') no-repeat;
    background-size: contain;
    cursor: pointer;
  }
  .inner {
    padding: 100px 0;

    .title {
      font-size: 1.75rem;
      font-weight: 800;
      margin-bottom: 2rem;
      letter-spacing: -0.4px;
    }

    .privacy-content {
      display: flex;
      flex-direction: column;
      gap: 50px;

      h4 {
        text-align: center;
        font-size: 1.125rem;
        font-weight: 600;
        margin-bottom: 2rem;
        color: #444;
      }

      .privacy-sections {
        position: relative;
        width: 1200px;
        margin: 0 auto;
        padding: 80px 0;
        display: flex;
        flex-direction: column;
        gap: 60px;
        border-top: 1px solid #e0e0e0;
        border-bottom: 1px solid #e0e0e0;

        .privacy-section {
          h6 {
            font-size: 1.25rem;
            margin-bottom: 20px;
          }
          p {
            font-size: 1rem;
            color: #3f3f3f;
            line-height: 1.6rem;
            font-weight: 500;
          }
          ul {
            margin-left: 1.8rem;
            li {
              list-style-type: disc;
              color: #666;
              line-height: 2;
            }
          }
        }
      }

      .privacy-date {
        text-align: center;
        font-weight: 700;
        color: #666;
      }
    }

    .ethics-content {
      display: flex;
      flex-direction: column;
      gap: 50px;
      text-align: center;

      h4 {
        text-align: center;
        font-size: 1.125rem;
        line-height: 1.6rem;
        font-weight: 600;
        margin-bottom: 2rem;
        color: #444;
      }

      .ethics-sections {
        position: relative;
        width: 1200px;
        margin: 0 auto;
        padding: 80px 0;
        display: flex;
        flex-direction: column;
        gap: 80px;
        border-top: 1px solid #e0e0e0;
        border-bottom: 1px solid #e0e0e0;

        .ethics-section {
          p {
            font-size: 18px;
            color: #3f3f3f;
            line-height: 1.6;
            margin-bottom: 1rem;
            font-weight: 700;
          }
          li {
            color: #666;
            margin-bottom: 1rem;
          }
        }
      }
    }

    .gray-box {
      width: 1200px;
      margin: 100px auto 0;
      background: #f0f0f0;
      padding: 35px;
      border-radius: 6px;
      text-align: center;
      box-sizing: border-box;

      span {
        line-height: 1.4;
        color: #292929;
      }
    }
  }

  @media (max-width: 1440px) {
    .prev-btn {
      width: 40px;
      height: 40px;
    }
    .close-btn {
      width: 40px;
      height: 40px;
    }

    .inner {
      padding: 100px 20px;
      box-sizing: border-box;

      .privacy-content {
        .privacy-sections {
          width: 100%;
        }
      }

      .ethics-content {
        .ethics-sections {
          width: 100%;
        }
      }

      .gray-box {
        width: 100%;
      }
    }
  }

  @media (max-width: 767px) {
    .prev-btn {
      top: 24px;
      left: 20px;
      width: 35px;
      height: 35px;
    }
    .close-btn {
      top: 24px;
      right: 20px;
      width: 35px;
      height: 35px;
    }
    .inner {
      padding: 80px 20px;

      .privacy-content {
        gap: 40px;

        h4 {
          font-size: 15px;
          line-height: 1.6;
          margin-bottom: 1rem;
        }
        .privacy-date {
          font-size: 14px;
        }
        .privacy-sections {
          width: 100%;
          gap: 40px;
          padding: 60px 0;

          .privacy-section {
            h6 {
              font-size: 18px;
            }
            p {
              font-size: 14px;
            }
            ul {
              margin-left: 16px;
              li {
                font-size: 13px;
              }
            }
          }
        }
      }

      .ethics-content {
        gap: 40px;
        text-align: left;

        h4 {
          font-size: 15px;
          line-height: 1.6;
          margin-bottom: 1rem;
        }

        .ethics-sections {
          width: 100%;
          padding: 60px 0;
          gap: 40px;

          .ethics-section {
            p {
              font-size: 18px;
              line-height: 1.6;
              margin-bottom: 1rem;
              span {
                display: block;
              }
            }
            li {
              font-size: 14px;
              line-height: 1.6;
              margin-bottom: 1rem;
              font-weight: 500;
            }
          }
        }
      }

      .gray-box {
        box-sizing: border-box;
        width: 100%;
        margin: 40px auto 0;
        padding: 30px 20px;
        font-size: 14px;
      }
    }
  }
  @media (max-width: 575px) {
    .prev-btn {
      top: 20px;
      left: 20px;
      width: 25px;
      height: 25px;
    }
    .close-btn {
      top: 20px;
      right: 20px;
      width: 25px;
      height: 25px;
    }

  }
}
